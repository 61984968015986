// Generated by Framer (de6f63d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/Kl87dOv4A";

const enabledGestures = {G9Hzg2Y3B: {hover: true}, ic1J9bvr4: {hover: true}};

const cycleOrder = ["G9Hzg2Y3B", "ic1J9bvr4"];

const serializationHash = "framer-eES8Y"

const variantClassNames = {G9Hzg2Y3B: "framer-v-1txxdfp", ic1J9bvr4: "framer-v-1c3ert"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "G9Hzg2Y3B", "Variant 2": "ic1J9bvr4"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, jzokcxktd: title ?? props.jzokcxktd ?? "UX Design", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "G9Hzg2Y3B"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, jzokcxktd, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "G9Hzg2Y3B", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-eES8Y", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1txxdfp", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"G9Hzg2Y3B"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} {...addPropertyOverrides({"G9Hzg2Y3B-hover": {"data-framer-name": undefined}, "ic1J9bvr4-hover": {"data-framer-name": undefined}, ic1J9bvr4: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h4 className={"framer-styles-preset-1kpjmgx"} data-styles-preset={"Kl87dOv4A"}>UX Design</motion.h4></React.Fragment>} className={"framer-1qdabgr"} data-framer-name={"Almost before we kne"} layoutDependency={layoutDependency} layoutId={"OJ7hFtljL"} style={{"--framer-paragraph-spacing": "0px"}} text={jzokcxktd} variants={{"G9Hzg2Y3B-hover": {"--extracted-1eung3n": "var(--token-08633ce2-794d-47e8-9f1d-c93f863e9c58, rgb(62, 54, 166))"}, "ic1J9bvr4-hover": {"--extracted-1eung3n": "var(--token-08633ce2-794d-47e8-9f1d-c93f863e9c58, rgb(62, 54, 166))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"G9Hzg2Y3B-hover": {children: <React.Fragment><motion.h4 className={"framer-styles-preset-1kpjmgx"} data-styles-preset={"Kl87dOv4A"} style={{"--framer-text-color": "var(--extracted-1eung3n, var(--token-08633ce2-794d-47e8-9f1d-c93f863e9c58, rgb(62, 54, 166)))"}}>UX Design</motion.h4></React.Fragment>}, "ic1J9bvr4-hover": {children: <React.Fragment><motion.h4 className={"framer-styles-preset-1kpjmgx"} data-styles-preset={"Kl87dOv4A"} style={{"--framer-text-color": "var(--extracted-1eung3n, var(--token-08633ce2-794d-47e8-9f1d-c93f863e9c58, rgb(62, 54, 166)))"}}>UX Design</motion.h4></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-eES8Y [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eES8Y .framer-1wvi9jj { display: block; }", ".framer-eES8Y .framer-1txxdfp { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 10px 16px 10px 16px; position: relative; width: min-content; }", ".framer-eES8Y .framer-1qdabgr { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eES8Y .framer-1txxdfp { gap: 0px; } .framer-eES8Y .framer-1txxdfp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-eES8Y .framer-1txxdfp > :first-child { margin-left: 0px; } .framer-eES8Y .framer-1txxdfp > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 63
 * @framerIntrinsicWidth 202
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ic1J9bvr4":{"layout":["auto","auto"]},"nCTlUJPIQ":{"layout":["auto","auto"]},"tTnltO4EM":{"layout":["auto","auto"]}}}
 * @framerVariables {"jzokcxktd":"title"}
 * @framerImmutableVariables true
 */
const FramerL3nFDgwhq: React.ComponentType<Props> = withCSS(Component, css, "framer-eES8Y") as typeof Component;
export default FramerL3nFDgwhq;

FramerL3nFDgwhq.displayName = "Skill Item";

FramerL3nFDgwhq.defaultProps = {height: 63, width: 202};

addPropertyControls(FramerL3nFDgwhq, {variant: {options: ["G9Hzg2Y3B", "ic1J9bvr4"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, jzokcxktd: {defaultValue: "UX Design", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerL3nFDgwhq, [...sharedStyle.fonts])